import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Hexagons from 'components/Hexagons';
import StepsIconBottom from 'components/StepsIconBottom';
import StepsIconTop from 'components/StepsIconTop';
import { useNavigate } from 'react-router-dom';
import { theme } from 'themes';

const Header = styled(Box)({
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    margin: '10px 0',
});

const BoxContainer = styled(Box)({
    display: 'flex',
    alignItems: 'self-start',
    overflow: 'hidden',
    width: '100%',
    justifyContent: 'center',
});

const TypographyTitle = styled(Typography)((props) => ({
    align: 'left',
    marginTop: '24px',
    padding: '10px 20px',
    color: props.theme.palette.primary.main,
}));

const BoxTitle = styled(Box)({
    padding: '15px 20px',
    height: '60px',
    borderRadius: '8px 8px 0px 0px',
});

const BoxBenefits = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: '10px 20px',
    width: '100%',
    height: '320px',
    borderRadius: '10px',
    border: '1px solid',
});

export default function AboutToPlataform() {
    const navigate = useNavigate();

    const sellerStepsList = [
        {
            number: '1',
            title: 'Cadastro e proposta',
            image: '/static/image/1-seller.svg',
            color: '#E84A9A',
        },
        {
            number: '2',
            title: 'Reunião inicial',
            image: '/static/image/2-seller.svg',
            color: '#6727E1',
        },

        {
            number: '3',
            title: 'Envio das informações',
            image: '/static/image/3-seller.svg',
            color: '#1E49E2',
        },
        {
            number: '4',
            title: 'Análise',
            image: '/static/image/4-seller.svg',
            color: '#DFD36A',
        },

        {
            number: '5',
            title: 'Anúncio',
            image: '/static/image/5-seller.svg',
            color: '#56BDAD',
        },
        {
            number: '6',
            title: 'Notificação dos compradores',
            image: '/static/image/6-seller.svg',
            color: '#E84A9A',
        },

        {
            number: '7',
            title: 'Assinatura de NDAs',
            image: '/static/image/7-seller.svg',
            color: '#6727E1',
        },

        {
            number: '8',
            title: 'Recebimento de ofertas',
            image: '/static/image/8-seller.svg',
            color: '#1E49E2',
        },
        {
            number: '9',
            title: 'Negociação com o comprador',
            image: '/static/image/9-sellerBuyer.svg',
            color: '#DFD36A',
        },

        {
            number: '10',
            title: 'Fechamento da venda',
            image: '/static/image/10-sellerBuyer.svg',
            color: '#56BDAD',
        },
    ];

    const buyerStepsList = [
        {
            number: '1',
            title: 'Cadastro',
            image: '/static/image/1-buyer.svg',
            color: '#E84A9A',
        },
        {
            number: '2',
            title: 'Definição do perfil',
            image: '/static/image/2-buyer.svg',
            color: '#6727E1',
        },
        {
            number: '3',
            title: 'Acesso às oportunidades',
            image: '/static/image/3-buyer.svg',
            color: '#1E49E2',
        },
        {
            number: '4',
            title: 'Declaração de interesse',
            image: '/static/image/4-buyer.svg',
            color: '#DFD36A',
        },

        {
            number: '5',
            title: 'Assinatura de NDA',
            image: '/static/image/5-buyer.svg',
            color: '#56BDAD',
        },
        {
            number: '6',
            title: 'Recebimento das informações',
            image: '/static/image/6-buyer.svg',
            color: '#E84A9A',
        },

        {
            number: '7',
            title: 'Avaliação do Ativo',
            image: '/static/image/7-buyer.svg',
            color: '#6727E1',
        },
        {
            number: '8',
            title: 'Oferta de compra',
            image: '/static/image/8-buyer.svg',
            color: '#1E49E2',
        },
        {
            number: '9',
            title: 'Negociação com o vendedor',
            image: '/static/image/9-sellerBuyer.svg',
            color: '#DFD36A',
        },
        {
            number: '10',
            title: 'Fechamento da venda',
            image: '/static/image/10-sellerBuyer.svg',
            color: '#56BDAD',
        },
    ];

    return (
        <>
            <Header>
                {/* <Typography
                    variant='h1'
                    align='left'
                    marginBottom='8px'
                    sx={{ color: theme.palette.themePrimary.primaryGray }}
                >
                    Sobre a Plataforma
                </Typography> */}
                <Button
                    type='button'
                    onClick={() => navigate('/')}
                    sx={{
                        padding: '10px'
                    }}
                    style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                >
                    Voltar
                </Button>
            </Header>

            {/* Diferenciais KPMG Plataforma de Ativos Alternativos, uma ferramenta proprietária */}
            <Box>
            <Box>
                    <Grid
                        container
                        spacing={1}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <Grid
                            container
                            item
                            spacing={1}
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                        >
                            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                            <video
                            autoPlay
                                controls
                                width="900"
                                height="500"
                                src="/static/videos/Chamada.mp4" // Caminho relativo à pasta public
                            >
                                Seu navegador não suporta o elemento <code>video</code>.
                            </video>
                           
                        </Grid>
                    </Grid>
                </Box>
                <TypographyTitle variant='h2'>
                    Diferenciais KPMG Plataforma de Ativos Alternativos, uma
                    ferramenta proprietária
                </TypographyTitle>
                <Typography
                    variant='body2'
                    sx={{
                        margin: '10px 20px',
                        textAlign: 'left',
                        color: theme.palette.themePrimary.primaryGray,
                    }}
                >
                    A KPMG desenvolveu ferramenta proprietária
                </Typography>
                <Hexagons />
               
            </Box>

            {/* Benefícios KPMG Plataforma de Ativos Alternativos */}
            <Box>
                <TypographyTitle variant='h2'>
                    Benefícios KPMG Plataforma de Ativos Alternativos
                </TypographyTitle>
              
                <BoxContainer
                    sx={{ display: 'flex', justifyContent: 'space-around' }}
                >
                    <BoxBenefits sx={{ borderColor: '#6481EB' }}>
                        <BoxTitle sx={{ background: '#6481EB' }}>
                            <Typography
                                sx={{
                                    color: '#FFFFFF',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Para o comprador
                            </Typography>
                        </BoxTitle>
                        <Box sx={{ padding: '10px 30px' }}>
                            <ul
                                style={{
                                    color: '#6481EB',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <li style={{ padding: '5px 0px' }}>
                                    Acesso a diversas oportunidades de mercado
                                    em um só lugar.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Vasta gama de tipos de ativos.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Custo zero.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Análise prévia dos ativos.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Notificações automáticas de acordo com o
                                    perfil do comprador.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Curadoria KPMG.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Organização do processo de compra e
                                    apresentação de ofertas.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Mais agilidade no processo de compra.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Serviços acessórios durante toda a
                                    transação.
                                </li>
                            </ul>
                        </Box>
                    </BoxBenefits>

                    <BoxBenefits sx={{ borderColor: '#C590E5' }}>
                        <BoxTitle sx={{ background: '#C590E5' }}>
                            <Typography
                                sx={{
                                    color: '#FFFFFF',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                }}
                            >
                                Para o vendedor
                            </Typography>
                        </BoxTitle>
                        <Box sx={{ padding: '10px 30px' }}>
                            <ul
                                style={{
                                    color: '#C590E5',
                                    fontSize: '13px',
                                    fontWeight: 'bold',
                                }}
                            >
                                <li style={{ padding: '5px 0px' }}>
                                    Acesso a diversos potenciais compradores em
                                    um só lugar.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Processo competitivo e organizado, que torna
                                    mais fácil aumentar a quantidade de ofertas
                                    e o valor do ativo.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Acesso a serviços adicionais da KPMG, tais
                                    como <i>Due Diligence</i>, <i>Valuation</i>{' '}
                                    e Assessoria Fiscal.
                                </li>
                                <li style={{ paddingBottom: '5px' }}>
                                    Honorários majoritariamente no sucesso da
                                    transação.
                                </li>
                            </ul>
                        </Box>
                    </BoxBenefits>
                </BoxContainer>
            </Box>

            {/* Etapas da Plataforma: Vendedor */}
            <Box>
                <TypographyTitle variant='h2'>
                    Etapas da Plataforma: Vendedor
                </TypographyTitle>

                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Grid
                        container
                        item
                        spacing={1}
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        {sellerStepsList.map((item) =>
                            item.number % 2 === 0 ? (
                                <StepsIconTop
                                    number={item.number}
                                    title={item.title}
                                    image={item.image}
                                    color={item.color}
                                />
                            ) : (
                                <StepsIconBottom
                                    number={item.number}
                                    title={item.title}
                                    image={item.image}
                                    color={item.color}
                                />
                            )
                        )}
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                            controls
                            width="900"
                            src="/static/videos/Tutorial_vendedor.mp4" // Caminho relativo à pasta public
                        >
                            Seu navegador não suporta o elemento <code>video</code>.
                        </video>
                    </Grid>
                </Grid>
            </Box>

            {/* Etapas da Plataforma: Comprador */}
            <Box>
                <TypographyTitle variant='h2'>
                    Etapas da Plataforma: Comprador
                </TypographyTitle>

                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '100%',
                    }}
                >
                    <Grid
                        container
                        item
                        spacing={1}
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                        }}
                    >
                        {buyerStepsList.map((item) =>
                            item.number % 2 === 0 ? (
                                <StepsIconTop
                                    number={item.number}
                                    title={item.title}
                                    image={item.image}
                                    color={item.color}
                                />
                            ) : (
                                <StepsIconBottom
                                    number={item.number}
                                    title={item.title}
                                    image={item.image}
                                    color={item.color}
                                />
                            )
                        )}
                        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                        <video
                            controls
                            width="900"
                            style={{marginBottom:100}}
                            src="/static/videos/Tutorial_comprador.mp4" // Caminho relativo à pasta public
                        >
                            Seu navegador não suporta o elemento <code>video</code>.
                        </video>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
